  /* orgchart css */
  .org-chart {
    height: calc(100vh - 218px);
    border:1px solid #3f4145;
    border-radius: 10PX;
  }
  .org-chart > svg {
    height: 100%;
  }
  .node-container {
    min-height: 140px;
    background-color: #2a233d;
    border: 1px solid #646464;
    color: #227c9d;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    transition: .25s;
  }
  .node-container-teams {
    background-color: #171822;
  }
  .node-details {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .node-details-listed {
    border-right: 5px solid #0d6efd;
  }
  .node-container:hover {
    background-color: #223e7d;
  }
  .node-content {
    display: flex;
  }
  .node-team {
    width: 100%;
    text-align: center;
  }
  .node-team-name {
    font-family: Ubuntu !important;
    margin-bottom: 0.5rem;
    color: #fef9ef;
    font-size: 1.2rem;
  }
  .node-team-member-img {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0.2rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }
  .node-team-member-img-default {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0.2rem;
    border-radius: 50%;
    background-color: #2a233d;
    color: #FFF;
    padding-top: 6px;
    text-align: center;
  }
  .node-details .node-team-member-img-default svg {
    position: absolute;
    margin-left: -17.5px;
  }

  .node-img {
    min-width: 90px;
    height: 90px;
    border-radius: 1rem;
    background-size: cover;
    background-position: center center;
  }
  .node-img-default {
    width: 90px;
    height: 90px;
    border-radius: 1rem;
    background-color: #171822;
    color: #FFF;
    text-align: center;
    padding-top: 8px;
  }
  .node-info {
    margin-left: 1.5rem;
    color: #fef9ef;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .node-name {
    font-family: Ubuntu !important;
    padding-bottom: 0.3rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    line-height: 1.6rem;
  }
  .node-role {
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    font-family: Ubuntu !important;
  }
  .node-department {
    font-family: Ubuntu !important;

    padding: 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: #0d6efd;
    border-radius: 5px;
    color: #FFF;
  }
  .node-department > svg {
    margin-right: 0.5rem;
  }
  .org-expand-btn {
    width: 40px;
    height: 40px;
    margin: auto;
    color: #227c9d;
    background-color: #fef9ef;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #d3d3d3;
    border-radius: 50%;
    cursor: pointer;
    font-size: .9rem;
    font-weight: bold;
  }
  .org-expand-btn > span:last-child {
    display: flex;
  }
  .org-add-btn {
    width: 40px;
    height: 40px;
    margin: auto;
    color: #227c9d;
    background-color: #fef9ef;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #d3d3d3;
    border-radius: 50%;
    cursor: pointer;
    font-size: .9rem;
    font-weight: bold;
    position: absolute;
    margin-left: 180px;
    margin-top: -20px;
    z-index: 10000;
  }
  /* detail css */
  .detail-container {
    position: absolute;
    top:70px;
    left:0px;
    width: 100%;
    height: calc(100vh - 70px);
    background: #171822;
    padding-top: 40px;
  }
  .detail {
    background: #171822;
    padding:25px;
    border:2px solid #FFF;
    border-radius: 10px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 580px) {
    .detail {
        width: 90%;
      }
  }
  .detail-close-btn {
    position: absolute;
    top: 60px;
    right: 22%;
    width: 30px;
    height: 30px;
    color: #227c9d;
    background-color: #fef9ef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #227c9d;
    cursor: pointer;
  }
  .detail-img {
    width: 200px;
    height: 200px;
    border-radius: 1rem;
    background-size: cover;
    background-position: center center;
    margin: 0 auto;
  }
  .detail-img-default {
    width: 200px;
    height: 200px;
    border-radius: 1rem;
    background-color: #171822;
    color: #FFF;
    text-align: center;
    padding-top: 22px;
    margin: 0 auto;
  }
  .detail-name {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .detail-role {
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  .detail-item {
    width: 100%;
    margin: 1rem 0;
    font-size: 0.9rem;
    border-bottom: #555 1px solid;
  }
  .detail-item-label {
    margin: 0.5rem 0;
    font-weight: bold;
  }
  .detail-item-value {
    text-align: justify;
  }
  .detail-item-team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #555;
    padding: 15px 0px;
    cursor: pointer;
    border-radius: .5rem;
    transition: .2s;
  }
  .detail-item-team:hover {
    background-color: #b97719;
  }
  .detail-item-img {
    width: 50px;
    height: 50px;
    margin: 0.2rem;
    border-radius: 50%;
  }
  .detail-item-name {
    margin-top: 15px;
    margin-left: 1rem;
    font-weight: bold;
  }
  .detail-item-role {
    font-size: 0.8rem;
    margin-left: 0.5rem;
    margin-top: 5px;
    background-color: #0d6efd;
    border-radius: 5px;
    color: #FFF;
    padding: 3px;
  }
  .btn-network-viewer {
    position: absolute;
    right: 45px;
    top:20px;
    color:#1e212e;
    margin-top:-73px;
    border:0px;
    color: #999;
    background: none;
}
.btn-network-viewer:hover {
    color: #ccc;
}
.detail-shortcuts {
  margin-bottom: 15px;
  text-align: center;
}
.detail-shortcuts a, .detail-shortcuts button {
  display: inline-block;
  color: #fef9ef;
  background-color: #0d6efd;
  padding:18px;
  border-radius: 3rem;
  transition: .3s;
  margin:10px;
  border:none;
}
@media screen and (max-width: 580px) {
  .detail-shortcuts a, .detail-shortcuts button {
    padding:22px;
  }
}
.detail-shortcuts a:hover, .detail-shortcuts button:hover {
  color: #fef9ef;
  background-color: #b97719;
}

.network-panel .table-responsive {
  margin-top: -14px;
}
.network-panel .global-filter {
  margin:12px 6px 6px 6px;
}
.side-img {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}
.side-img-default {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 1rem;
  background-color: #2a233d;
  color: #FFF;
  padding-top: 6px;
  text-align: center;
}
.side-img-default-location {
  margin-top: 5px;
  display: block;
}
.network-panel table tr td {
  vertical-align: middle;
  padding: 5px;
  padding-left: 15px;
}
.board-network-shortcuts {
  margin-top:3px;
  margin-bottom: 6px;
}
.invite-share {
  color: #999;
  transition: .5s;
}
.invite-share:hover {
  color: #FFF;
}
.board-network-shortcuts a {
  display: inline-block;
  color: #fef9ef;
  background-color: #0d6efd;
  padding:0px 13px 3px 13px !important;
  border-radius: 3rem;
  transition: .3s;
  margin:4px;
  border:none;
}
.board-network-shortcuts a:hover {
  color: #fef9ef;
  background-color: #b97719;
}
.board-network-status {
  font-size: 0.8rem;
  margin-top: 10px;
  background-color: #0d6efd;
  border-radius: 5px;
  color: #FFF !important;
  padding: 5px;
  line-height:normal;
  width:100px;
  margin-left:0px;
  text-align: center;
}
.status-pending  {
  background-color: #b97719 !important;
  color: #FFF !important;

}
.status-attended  {
  background-color: #118f46 !important;
  color: #FFF !important;
}
.status-declined  {
  background-color: #b9192e !important;
  color: #FFF !important;
}
.status-summary {
  margin-right: 5px;
}
button.status-declined:hover {
  background-color: #981829 !important;
}
button.status-attended:hover {
  background-color: #127039 !important;
}
button.status-result  {
  background-color: #3f4145 !important;
  color: #FFF !important;
}
button.status-result:hover {
  background-color: #303134 !important;
}

.invite-actions button {
  margin:8px 5px;
}
.board-network {
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: flex-start;
  border-bottom: 1px solid #555;
  padding: 10px 0px;
  border-radius: .5rem;
  font-size: 1.2rem;
  line-height: 40px;
}
.board-network .node-img {
  width:100px;
  height: 100px;
}
.board-network .node-img-default {
  width:100px;
  height: 100px;
  padding-top:26px;
  background-color: #2a233d;
}
.board-network > div {
  margin-left:10px;
}

.board-network-link {
  font-size: 12px;
  line-height: normal;
  padding:5px;
}

.board-network-options {
  text-align: center;
}

.board-network-options .board-network-status {
  width: 318px;
  display: inline-block;
}
.board-network-options .board-network-instruction {
  padding-top:11px;
  display: inline-block;
}
.board-network-options .invite-actions button {
  padding:10px 44px;
}
.board-network-options .invite-actions {
  text-align: center;
  width: 100%;
  margin-left:0px;
}
.network-link {
  border:0px !important;
  background: none !important;
  padding:0px !important;
  display: block;
  transition: .5s;
}
.network-link:hover {
  opacity: .6;
}