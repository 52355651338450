html,
body,
#root {
    height: 100%;
}

body {
    font-family: Ubuntu !important;
    letter-spacing: 0.5px;
    background: #1e212e;
}
.div-center {
    margin: 0 auto;
}
.div-left {
    float: left;
}
.hide {
    display: none;
}
.clear {
    clear:both;
}
.app-container {
    min-height: 100%;
    color: #FFF;
}

.navbar {
    background: #171822;
    padding: 0.9rem !important;
    border-bottom: 1px solid #777f8f;
}

.navbar-brand {
    color: #bbb !important;
    transition: .2s;
}

.nav-link {
    color: #bbb !important;
}

.navbar-toggler {
    background-color: #999a9b !important;
}

.navbar-nav a:hover,
.navbar-brand:hover {
    color: #0d6efd !important;
}

.nav-active {
    color: #FFF !important;
}
@media screen and (max-width: 1260px) and (min-width: 991px) {
    .navbar-nav a span {
        display: none;
    }
}
@media screen and (max-width: 990px) {
    .navbar .nav-link {
        padding:15px 0px 15px 15px !important;
        border-bottom: 1px solid #646464;
        transition: 0s;
    }
    .navbar .nav-link:hover {
        background: #23273d;
    }
}

.navbar-toggler {
    background-color: #224baa !important;
    color: #171822 !important;
    transition: .5s;
}
.navbar-toggler:hover {
    background-color: #b97719 !important;
}

.home-nav a,
.home-nav button {
    display: inline-block;
    width: 300px;
    height: 150px;
    background-color: #171822;
    border: 1px solid #777f8f;
    border-bottom: #0d6efd 5px solid;
    text-align: center;
    padding-top: 24px;
    color: #FFF !important;
    margin: 10px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 15px;
    transition: .25s;
}

.home-nav-2 button {
    border-bottom: #45317a 5px solid;
}

.home-nav button {
    padding-top: 20px;
}

.home-nav a:hover,
.home-nav button:hover {
    background-color: #1c3369;
    border: 1px solid #777f8f;
    border-bottom: #b97719 5px solid;
}

.home-nav a span,
.home-nav button span {
    display: block;
    margin-bottom: 10px;
}
@media screen and (max-width: 688px) {
    .home-nav a,
    .home-nav button {
        width: 45%;
        font-size: 16px;
        margin: 6px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.modal-btn-right {
    float: right;
    margin-left: 5px;
}

.modal-dialog {
    color: #FFF;
    border: 2px solid #FFF;
    border-radius: 10px;
}

.card {
    border: 2px solid #fff;
    border-radius: 0.25rem;
}

.card-header,
.modal-header {
    background-color: #171822;
}

.card-body,
.modal-body {
    background-color: #1e212e;
}

.modal-body {
    padding: 25px;
}

.btn-login {
    width: 100%;
}

.error {
    font-size: 80%;
    color: #dc3545;
}

.panel-side {
    height: 286px;
    overflow-y: scroll;
}

.panel {
    padding: 25px 15px;
}

.panel-content {
    padding: 15px;
    border-top: 1px solid #777f8f;
    overflow-x: scroll;
}
.panel-thin {
    padding: 15px;
}
.panel-side-accounts .panel-side-hide {
    display: none;
}
.panel-fill {
    background-color:#1e212e;
    overflow-y: scroll;
}
@media screen and (min-width: 991px) {
    .panel {
        padding: 25px 35px;
    }
    .panel-side {
        position: fixed;
        width: 400px;
        top: 106px;
        bottom: 0;
        height: auto;
        border-right: 1px solid #777f8f;
    }

    .panel-content {
        position: fixed;
        border-top: 0px;
        margin-left: 400px;
        top: 106px;
        bottom: 0;
        width: calc(100% - 400px);
    }

    .panel-content::-webkit-scrollbar {
        width: 10px;
    }

    .panel-content::-webkit-scrollbar-track {
        border-radius: 12px;
        background: #1e212e;
    }

    .panel-content::-webkit-scrollbar-thumb {
        background: #b91946;
        border-radius: 10px;
    }

    .panel-content::-webkit-scrollbar-thumb:hover {
        background: #b97719;
    }
}

div::-webkit-scrollbar, body::-webkit-scrollbar {
    margin-left: 1px;
    width: 10px;
}

div::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #1e212e;
    border-left: 1px solid #777f8f;

}

div::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background: #b91946;
    border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
    background: #b97719;
}
.panel-side-separator {
    border-top: 3px solid #f2c960;
}
.panel-side-row {
    padding: 15px;
    background-color: #23273d;
    display: flex;
    border-bottom: 1px solid #0b0b0b;
    transition: .2s;
}

.panel-side-row>.panel-side-title {
    flex-grow: 2
}

.panel-side-type-summary {
    background-color: #231d35 !important;
    border-bottom: 0;
}
.panel-side-type-summary:nth-child(odd) {
    background-color: #2a233d !important;
}

.panel-side-type-total {
    background-color: #39405e;
}

.panel-side-type-accounttype {
    background-color: #256b3e;
    cursor: pointer;
}
.panel-side-open > div {
    border-left:8px solid #a4c639;
}

.panel-side-type-bank {
    background-color: #223e7d;
    padding-left: 35px;
}

.panel-side-type-current {
    background-color: #b97719;
}

.panel-side-type-account,
.panel-side-type-board-add {
    padding-left: 40px;
    cursor: pointer;
}

.panel-side-type-board {
    background-color: #223e7d;
    padding-left: 40px;
    cursor: pointer;
}

.panel-side-type-board:nth-child(odd) {
    background-color: #1c3369;
}

.panel-side-type-vault {
    background-color: #2a233d;
    padding-left: 40px;
    cursor: pointer;
}

.panel-side-type-vault:nth-child(odd) {
    background-color: #231d35;
}

.panel-side-type-network {
    background-color: #39393a;
    padding-left: 40px;
    cursor: pointer;
}

.panel-side-type-network:nth-child(odd) {
    background-color: #26262c;
}

.panel-side-type-account span {
    margin-right: 8px;
}

.panel-side .selected {
    background-color: #9e671a;
}

.panel-tagged {
    border-right: 6px solid #a4c639 !important;
}

.panel-side-type-accounttype:hover, 
.panel-side-type-account:hover,
.panel-side-type-board:hover,
.panel-side-type-board-add:hover,
.panel-side-type-vault:hover,
.panel-side-type-network:hover {
    background-color: #b97719;
}

.td-button {
    display: block;
    width: 100%;
    cursor: pointer;
}

.td-currency {
    text-align: right;
}

.table {
    color: #FFF;
    border: 0px solid;
}

.table th {
    letter-spacing: 2px;
    background-color: #1e212e;
}

.table thead {
    border-color: #1e212e;
}

.table tbody td {
    border: 1px solid #1e212e;
}

.table tbody td,
.table thead th {
    padding: 12px;
}

.table tbody tr:nth-of-type(even) {
    background-color: #464a5a;
}

.table tbody tr:nth-of-type(odd) {
    background-color: #39405e;
}

.table tbody tr.future-transaction {
    background-color: #223e7d;
    color: #999a9b;
}

.table tbody tr.selected {
    background-color: #b91946;
}

.table tbody tr {
    cursor: pointer;
}

.table tbody tr:hover {
    color: #FFF;
    background-color: #b97719;
}

@media screen and (max-width: 580px) {
    .table {
        font-size: .7rem;
    }
}

.btn-full-width {
    width: 100%;
    margin-bottom: 12px;
}

.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.typeahead-form-group {
    position: relative;
}

.typeahead-list-group {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    z-index: 100;
}

.typeahead-list-group-item {
    padding: 0.3rem 1.3rem;
    background-color: #fff;
}

.typeahead-list-group-item:hover {
    cursor: pointer;
    background: #646464;
    color: #fff;
}

.typeahead-spinner-container {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.dropdown-transactions {
    display: inline-block;
}

.menu-content {
    padding-top: 10px;
}

.menu-content>div {
    width: 50%;
    float: left;
}

.menu-content button,
.menu-content input {
    margin-bottom: 8px;
}

.menu-content .transition-filters span {
    width: 31%;
    float: left;
}

span.transition-filters-to {
    width: 3% !important;
    text-align: center;
    margin-top: 6px;
    padding: 0px 2px !important;
}

.menu-content .transition-filters span {
    padding: 0px 5px;
}

@media screen and (max-width: 580px) {
    .menu-content>div {
        width: 100%;
    }

    .title-icon {
        display: none;
    }
}
@media screen and (max-width: 616px) {
    .transition-filters  {
        margin-top: 10px;
    }
}
.charts svg {
    cursor: pointer;
}
.charts {
    float: left;
    width: 350px;
    cursor: pointer;
}

.charts>div {
    text-align: center;
}

.chart-header {
    position: relative;
    margin-bottom: -70px;
    z-index: 200;
}
.chart-header button {
    padding: 8px 24px;
    font-size: 18px;
    letter-spacing: .5px;
}

.chart-footer {
    margin-bottom: 50px;
    margin-top: -70px;
}

.chart-footer-content {
    padding-left: 100px;
    text-align: left;
}

.chart-income {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #223e7d;
    border: 2px solid #FFF;
    border-radius: 3px;
}

.chart-expenses {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #b91946;
    border: 2px solid #FFF;
    border-radius: 3px;
}

.chart-subtitle {
    padding-left: 30px;
}

.chart-subtitle>span {
    font-weight: bold;
}
.chart-gross-gain {
    background-color: #118f46;
    padding: 8px 16px;
    border-radius: 8px;
    margin-left: 5px;
    border:1px solid #FFF;
}
.chart-gross-loss {
    background-color: #b9192e;
    padding: 8px 16px;
    border-radius: 8px;
    margin-left: 5px;
    border:1px solid #FFF;
}
.charts hr {
    margin-bottom: 40px;
    width: 90%;
    margin-left: 5%;
}

.button-text {
    background: none;
    border: 0px;
    color: #FFF;
    transition: .2s;
}

.button-text:hover {
    color: #b97719;
}

.board-list-container {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
}

.board-list-add {
    background-color: #0d6efd;
    padding: 35px 20px 35px 20px;
    border-radius: 10px;
    border: 1px solid #0a53be;
    font-size: 1.1rem;
    cursor: pointer;
    writing-mode: vertical-rl;
    letter-spacing: 1.6px;
}

.board-list-add:hover {
    background-color: #0a53be;
    transition: .2s;
}

.board-list-add span {
    position: absolute;
}

.board-list {
    background-color: #2a233d;
    width: 320px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #646464;
    font-size: 1.2rem;
}

.board-list .board-list-options {
    margin-top: 10px;
    text-align: center;
}

.board-list .board-list-option {
    background: none;
    color: #FFF;
    border: 0px;
    margin: 0px 20px;
    transition: .2s;
}

.board-list .board-list-option:hover {
    color: #b97719;
}

.board-list .board-list-cards-container {
    padding: 0px 0px 10px 0px;
    white-space: normal;
}

.board-list .board-list-cards-container .board-list-card {
    background-color: #171822;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    white-space: normal;
    width: 90%;
    display: inline-block;
    padding: 15px;
    transition: .25s;
    border: 1px solid #777f8f;
    border-bottom: #0d6efd 5px solid;
}

.board-list .board-list-cards-container .board-list-card span {
    padding-right: 5px;
    ;
}

.board-list .board-list-cards-container .board-list-card .text-right div {
    padding-top: 3px;
}

.board-list .board-list-cards-container .board-list-card .board-list-card-date {
    font-size: .9rem;
    letter-spacing: 1.2px;
}

.board-list-card-options {
    width: 10%;
    display: inline-block;
    vertical-align: top;
    margin-top: -5px;
}

.board-list .board-list-card-option {
    background: none;
    color: #FFF;
    border: 0px;
    display: block;
    margin-bottom: -5px;
}

.board-list .board-list-cards-container .board-list-card:hover {
    background-color: #223e7d;
}

.board-list .board-list-cards-container .board-list-card-option:hover {
    color: #b97719;
}

.board-list .board-list-cards {
    margin-bottom: 15px;
}

.board-search {
    margin-bottom: 12px;
}

.scroll-panel {
    white-space: nowrap;
}

.form-switch .form-check-input {
    width: 4em;
    height: 2em;
}

.board-list-card-cost {
    font-size: 1rem;
}

@media screen and (max-width: 1080px) {
    .text-center-mobile {
        text-align: center;
    }
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
    color: #FFF;
    text-decoration: none;
}
.fc .fc-scrollgrid-sync-inner {
    background-color: #171822;
}
.fc .fc-event {
    background-color: #224baa;
    border-color: #224baa;
    cursor: pointer;
    transition: .2s;
    font-weight: bold;
}
.fc .fc-event:hover {
    background-color: #b97719 !important;
    border-color: #b97719 !important;
}
.fc .fc-toolbar-title {
    font-size: 1.2rem;
}
.fc .fc-day-today .fc-scrollgrid-sync-inner {
    background-color: #227d37;
}
.fc-event-title {
    color: #FFF;
    font-size: 12px;
}
.fc-event-time {
    color: #bbb;
    font-size: 11px;
}
.fc-daygrid-event-dot {
    border-color: #a4c639;
}
.fc-daygrid-day-frame {
    cursor: pointer;
    transition: .5s;
}
.fc-daygrid-day-frame:hover {
    background: #39393a;
}
.fc-button-primary {
    background-color: #0a53be !important;
}
.fc-button-active {
    background-color: #227d37 !important;
}
.fc-theme-standard td, .fc-theme-standard th {
    border:1px solid #777;
}
@media screen and (max-width: 1800px) {
    .fc-event-title {
        font-size: 11px;
    }
    .fc-event-time {
        font-size: 10px;
    }
}
.fc-header-toolbar button {
    transition: .3s;
}
.fc-header-toolbar button:hover {
    background-color: #b97719 !important;
}
@media screen and (max-width: 580px) {
    .fc .fc-toolbar-title {
        position: absolute;
        margin-top: -50px;
        font-size: 1rem;
        left: 15px;
    }
    .fc-event-time{
        display: none;
    }
    .fc-header-toolbar {
        font-size: 13px;
    }
}
@media screen and (max-width: 420px) {
    .fc-daygrid-event-dot{
        display: none;
    }
}
.calendar-container {
    padding: 0px 15px;
}

.verification {
    width: 100%;
}

.verification form {
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    max-width: 400px;
    margin: 0 auto;
}

.verification .form-control {
    display: block;
    height: 50px;
    margin-right: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    min-width: 0;
}

.verification .form-control:last-child {
    margin-right: 0;
}

.see {
    position: absolute;
    right: 60px;
    color: #1e212e;
    margin-top: -32px;
    border: 0px;
    border-radius: 10px;
    transition: .2s;
}

.see:hover {
    color: #b97719;
}

.spinner-border {
    margin: 15px;
}

.version {
    opacity: .8;
    font-size: 14px;
    margin-right: 5px;
}

.poweredby img {
    height: 25px;
    opacity: .8;
    transition: .5s;
    margin-top: -8px;
}
.bdc img {
    height:26px;
    opacity: .6;
    transition: .5s;
    margin-top: -8px;
}
.poweredby img:hover, .bdc img:hover  {
    opacity: 1;
}

@media screen and (max-width: 1120px) {
    .version {
        position: absolute;
        right: 10px;
        top: 25px;
    }

    .poweredby {
        display: none;
    }
}

@media screen and (max-width: 990px) {

    .version {
        right: 80px;
    }
}

@media screen and (max-width: 427px) {
    .version {
        display: none;
    }
}

input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ccc !important;
    background-color: #171822 !important;

}

.form-control,
.form-control:focus,
.form-select,
.typeahead-list-group-item {
    background-color: #171822;
    color: #ccc;
    color-scheme: dark;
}

.form-control:read-only {
    color: #999;
    background-color: #23273d !important;
}

.typeahead-list-group-item {
    border: 1px solid #39405e;
}

.typeahead-list-group-item:hover {
    background-color: #b97719;
}

.form-check-input {
    background-color: #ddd;
    border: 1px solid #ccc;
    cursor: pointer;
}

.btn-card-viewer {
    position: absolute;
    right: 45px;
    color: #1e212e;
    margin-top: -73px;
    border: 0px;
    color: #999;
    background: none;
}

.btn-card-viewer:hover {
    color: #ccc;
}

.board-list-card-viewer-section {
    background-color: #171822;
    border-radius: 10px;
    font-size: 1rem;
    white-space: normal;
    width: 100%;
    padding: 15px;
    transition: .25s;
    border: 1px solid #777f8f;
    border-bottom: #0d6efd 5px solid;
    margin-bottom: 15px;
}

.file-list {
    margin-bottom: 24px;
}

.file-list a {
    display: inline-block;
    color: #FFF;
    text-decoration: none;
    padding: 15px 10px;
}

.file-list div {
    border-bottom: 1px solid #39405e;
    cursor: pointer;
}

.file-list div a:hover {
    background-color: #223e7d;

}

.file-list div svg {
    color: #b97719;
}

.file-list div button {
    background: none;
    border: 0;
}

.file-list div button svg:hover {
    color: #d54856;
}

.file-list div button svg {
    color: red;
}

.file-list div button:hover,
.file-list div button:focus {
    background: none;
    border: 0;
}

.android {
    color: #a4c639;
    text-decoration: none;
    transition: .5s;
}

.android:hover {
    color: #eee;
    text-decoration: none;
}

.android span {
    display: block;
    padding: 10px;
}

.profile-img {
    width: 100%;
    height: 300px;
    border-radius: 1rem;
    background-size: cover;
    background-position: center center;
}

.profile-img-default {
    width: 100%;
    height: 300px;
    border-radius: 1rem;
    background-color: #171822;
    color: #FFF;
    text-align: center;
    padding-top: 40px;
}

.profile-container {
    background-color: #2a233d;
    width: 320px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #646464;
    font-size: 1.2rem;
}

@media screen and (max-width: 990px) {
    .profile-container {
        margin: 0 auto;
    }
}

.profile-container button {
    width: 100%;
}

.map-container {
    height: 780px;
}

.map-container>div {
    border-radius: 8px;
}
.map-right {
    margin-left:10px;
    float: left;
    width:190px;
}
.map-name {
    color: #0b0b0b;
    font-weight: bold;
    font-family: Ubuntu !important;
    font-size: 16px;
}

.map-address {
    color: #0b0b0b;
    font-weight: bold;
    font-size: 12px;
    font-family: Ubuntu !important;
    line-height: 20px;
}

.map-date {
    color: #0b0b0b;
    font-weight: bold;
    font-size: 12px;
    font-family: Ubuntu !important;
    line-height: 20px;
    border-bottom: 1px solid #999;
    padding:2px 0px;
}
.map-date-light {
    color: #FFF;
    font-weight: bold;
    font-size: 12px;
    font-family: Ubuntu !important;
    line-height: 20px;
}
.map-history-btn {
    border:none;
    background: none;
    color:#0d6efd ;
    transition: .5s;
}
.map-history-btn:hover {
    color:#b97719 ;
}
.map-link {
    border:0px;
    transition: .5s;
}
.map-link:hover {
    opacity: .8;
}
.google-streetview {
    border-radius: 4px;
    margin:0px 0px 20px 0px;
}
.modal-full-height {
    height:90%;
    overflow: hidden;
}
.modal-full-height .modal-content, .modal-full-height .modal-body {
    height: 100%;
}
.modal-full-height .bottom-border {
    height:30px;
    width: 100%;
    background-color: #1e212e;
    position:absolute;
    bottom: 0;
}
.news {
    display: block;
    background-color: #2a233d;
    max-width: 800px;
    width:100%;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #646464;
    font-size: 1.2rem;
    margin-bottom: 15px;
    color:#FFF;
    text-decoration: none;
    transition: .5s;
}
.news:hover {
    color: #FFF;
    background-color: #b97719;
    text-decoration: none;
}
.news .news-img {
    display: block;
    width:100%;
    height: 300px;
    background-size: cover;
    background-color: #26262c;
    border-radius: 10px;
    margin:20px 0px;
}
.news .news-title {
    font-size: 28px;
    display: block;
}
.news .news-date {
    font-size: 14px;
    display: block;
}
.news-count {
    max-width: 800px;
    width:100%;
}

.pac-container {
    z-index: 1100 !important;
}

.gm-style-iw-d hr {
    color:#000;
}

.external-viewer {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 1000;
}

.external-viewer-close {
    cursor: pointer;
    position: fixed;
    top:29px;
    left:30px;
    padding: 30px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    transition: .6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    z-index: 1010;
    background: none;
    border:none;
    width: 80px;
    height: 80px;
}

.status-bar {
    height: 36px;
    background-color: #1b1f38;
    border-bottom: 1px solid #777f8f;
    font-size: 13px;
    padding:8px 18px;
    color: #bbb;
}
.status-bar #nav-dropdown-account:hover {
    color: #0d6efd !important;
}
.status-bar .date-min {
    display: none;
}
.status-bar svg {
    margin-top: -2px;
}
@media screen and (max-width: 500px) {
    .status-bar .date-full {
        display: none;
    }
    .status-bar .date-min {
        display: initial;
    }
}
@media screen and (max-width: 586px) {
    .status-bar .wish {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .no-padding-lg {
        padding: 0 !important;
        margin: 0 !important;
     }
}

.toggle-mobile {
    display: none;
}
@media screen and (max-width: 991px) {
    .toggle-mobile {
        display: block;
     }
     .toggle-desktop {
        display: none;
    }
}

.multiSelectContainer, .multiSelectContainer .optionContainer {
    background: #171822;
}
.multiSelectContainer li {
    padding: 6px !important;
}
.multiSelectContainer .searchBox  {
    color: #FFF;
}

.board-viewer {
    width: 100%;
    height:100%;
}

.directory {
    color:#a4c639;
}

.inventory-warning {
    color:#ffba58;
}
.inventory-danger {
    color:#ff425b;
}